<script>
export default {
  props: {
    outline: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: "",
    },
    classes: {
      type: [String, Array],
      default: "button",
    },
    type: {
      type: String,
    },
  },
  setup(props, context) {
    const attrs = computed(() => ({
      class: props.classes,
      ...(props.disabled && { disabled: props.disabled }),
      ...(props.link && { href: props.link }),
      ...(props.type && { type: props.type }),
    }));

    const tag = computed(() => (props.link ? "a" : "button"));

    return () =>
      h(
        tag.value,
        {
          ...attrs.value,
        },
        context.slots.default()
      );
  },
};
</script>
